@import "../index.scss";

.contact {
  display: flex;
  justify-content: flex-start;
  &__content {
    padding: 2rem;
    h1 {
      font-size: 2rem;
      text-align: center;
    }
    h3 {
      padding-top: 1.5em;
    }
    ul {
      list-style: none;
      padding: 1.2em 0;
    }
    &__title {
      display: flex;
      flex-direction: row;
      &__img {
        position: relative;
        top: 30px;
        left: 5px;
        font-size: 1.3em;
      }
      &__cont {
        position: relative;
        top: 26px;
        left: 15px;
        font-size: 2em;
      }
      &__social {
        position: relative;
        top: 28px;
        left: 5px;
        font-size: 1.7em;
      }
    }
    &__socialNetwork {
      &__logo {
        position: relative;
        top: 4px;
        font-size: 1.3em;
      }
      span {
        padding-left: 0.3em;
      }
      a {
        text-decoration: none;
        color: inherit;
        &:hover {
          color: $primary;
        }
      }
    }
    &__form {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.maps {
  overflow-x: hidden;
  position: relative;
  width: 101vw;
  height: 400px;
  left: -34px;
  @include breakpoint-low(md) {
    height: 500px;
    left: 0;
    width: 500px;
  }
  @include breakpoint-mid(md) {
    height: 600px;
    width: 60%;
    left: calc(50% - 30%);
  }
}
