@import "../index.scss";

.video {
  &__content {
    padding: 2em 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid $primary;
  }
}
