@import "./variables";

* {
  box-sizing: border-box;
  margin: 0;
}
html,
body {
  overflow-x: hidden;
}
body {
  position: relative;
  width: 100%;
  font-family: $Poppins;
  color: $dark;
}

#root {
  height: 100%;
}
