@import "../index.scss";

$length: 150px;

.eldo {
  display: none;
  height: $length;
  width: $length;
  position: fixed;
  right: -10px;
  bottom: calc(48% - ($length / 2));
  z-index: 90 !important;
  iframe {
    border: none;
  }
  @include breakpoint-eldo(md) {
    display: block;
  }
  &:hover {
    transform: scale(1.5);
    right: 0;
  }
  transition: 0.3s ease all;
}
