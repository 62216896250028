@import "../index.scss";

.footer {
  color: $light;
  background: linear-gradient($bg, $dark, $dark);
  padding-top: 3em;
  position: relative;
  width: 100%;
  &__content {
    &__social {
      // padding-top: 0.2em;
      display: flex;
      flex-direction: row;
      font-size: 2em;
      a {
        color: inherit;
        text-decoration: none;
        &:hover {
          color: $primary;
          transform: scale(1.2);
        }
        &:active {
          color: rgba($primary, 0.3);
        }
        transition: 0.3s ease all;
      }
    }
    &__row {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      h4 {
        padding-top: 1em;
      }
      @include breakpoint(md) {
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
      }
      &__column {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 1.5em 2em;
        ul {
          list-style: none;
          padding: 0;
          margin: 0;
          li {
            max-width: 200px;
            img {
              height: 70px;
              max-width: 300px;
              border-radius: $borderRadius;
            }
            a {
              text-decoration: none;
              color: inherit;
              border-radius: $borderRadius;
              transition: 0.3s ease all;
              &:hover {
                background: rgba($light, 0.1);
              }
              &:active {
                border-radius: calc(#{$borderRadius} + 6px);
                background: linear-gradient(
                  rgba($light, 0.1),
                  rgba($light, 0.2)
                );
              }
            }
            &:last-child {
              cursor: pointer;
              font-weight: bold;
              border-radius: $borderRadius;
              font-size: 1rem;
              font-family: inherit;
              background: $primary;
              color: $dark;
              border: 1px solid transparent;
              transition: 0.3s ease all;
              text-align: center;
              &:hover {
                border-color: $primary;
                background: rgba($primary, 0.1);
                color: $primary;
              }
              &:active {
                border-color: $primary;
                background: linear-gradient(
                  rgba($primary, 0.2),
                  rgba($primary, 0.3)
                );
                color: $primary;
              }
              a {
                display: block;
              }
            }
            padding: 0.1rem 0;
          }
        }
      }
      &__columnSm {
        a {
          text-decoration: none;
          color: inherit;
          &:hover {
            text-decoration: underline;
          }
        }
        text-align: center;
      }
    }
  }
}
