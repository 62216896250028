@import "../index.scss";
.form {
  padding: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  &__content {
    input,
    select {
      width: 100%;
      height: 4rem;
      margin: 0.5em 0;
      padding: 1em;
      font-size: 1em;
      border-radius: $borderRadius;
      text-align: center;
      @include breakpoint-low(md) {
        width: 30rem;
      }
      @include breakpoint-mid(md) {
        width: 45rem;
      }
    }
    p {
      color: rgba($dark, 0.5);
    }

    input:disabled,
    select:disabled {
      cursor: not-allowed;
    }
    button {
      cursor: pointer;
      width: 20rem;
      height: 3rem;
      position: relative;
      left: calc(50% - 10rem);
      border-radius: $borderRadius;
      background: $primary;
      color: $dark;
      border: 1px transparent solid;
      &:hover {
        border-color: $primary;
        background: rgba($primary, 0.1);
        color: $primary;
      }
      &:active {
        border-color: $primary;
        background: linear-gradient(rgba($primary, 0.2), rgba($primary, 0.3));
      }
    }
    button:disabled {
      cursor: not-allowed;
      background: rgba($primary, 0.7);
      color: rgba($dark, 0.8);
    }

    &__error {
      color: $red;
    }

    &__captcha {
      display: flex;
      justify-content: center;
      padding: 2em;
    }

    &__submited {
      font-weight: bolder;
      color: green;
    }

    &__mentions {
      text-align: center;
      @include breakpoint-low(md) {
        width: 30rem;
      }
      @include breakpoint-mid(md) {
        width: 45rem;
      }
      input {
        top: 3px;
        position: relative;
        width: 1em;
        height: 1em;
        font-weight: 1000;
      }
      font-size: 1em;
      &__accept {
        cursor: pointer;
        background: linear-gradient(
          rgba($green, 0.5),
          rgba($green, 0.8),
          $green
        );
        font-weight: 900;
        border: none;
        &:hover {
          background: linear-gradient(
            rgba($green, 0.3),
            rgba($green, 0.5),
            rgba($green, 0.8)
          );
        }
      }
      &__decline {
        background: linear-gradient(rgba($red2, 0.5), rgba($red2, 0.8), $red2);
        font-weight: 900;
        text-decoration: underline;
        border: none;
        cursor: pointer;
        &:hover {
          background: linear-gradient(
            rgba($red2, 0.3),
            rgba($red2, 0.5),
            rgba($red2, 0.8)
          );
        }
      }
    }
  }
}
