@import "../index.scss";

.home {
  &__content {
    overflow-x: hidden;
    font-size: 1.3em;
    &__title {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 3rem;
      font-size: 0.85em;
      @include breakpoint-low(md) {
        font-size: 1.3em;
      }
      h3 {
        border-bottom: 0.2em solid $primary;
      }
    }
    &__banner {
      & > img {
        position: relative;
        width: 100%;
        height: auto;
      }
    }
    &__description {
      &__right {
        padding: 2em;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &:not(:last-child) {
          border-bottom: 1px solid $dark;
        }
        @include breakpoint-mid(md) {
          padding: 4em;
          flex-direction: row;
          justify-content: flex-start;
        }
        & > img {
          height: auto;
          width: 100%;
          border-radius: 50px;
          @include breakpoint-low(md) {
            height: 400px;
            width: auto;
          }
        }
        & > p {
          padding-bottom: 1.5em;
          @include breakpoint-mid(md) {
            padding-right: 2em;
          }
        }
      }
      &__left {
        padding: 2em;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        &:not(:last-child) {
          border-bottom: 1px solid $primary;
        }
        @include breakpoint-mid(md) {
          padding: 4em;
          flex-direction: row;
          justify-content: flex-start;
        }
        & > img {
          height: auto;
          width: 100%;
          border-radius: 50px;
          @include breakpoint-low(md) {
            height: 400px;
            width: auto;
          }
        }
        & > p {
          padding-bottom: 1.5em;
          @include breakpoint-mid(md) {
            padding-left: 2em;
          }
        }
      }
    }
    &__qual {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      @include breakpoint-mid(md) {
        flex-direction: row;
      }
      img {
        height: 200px;
      }
      a {
        color: inherit;
        text-decoration: none;
        text-align: center;
        padding: 1em;
        transition: 0.3s ease all;
        &:hover {
          transform: scale(1.1);
          color: $red;
        }
      }
    }
  }
}
