@import "../index.scss";

.goTop {
  position: fixed;
  left: 40px;
  bottom: 40px;
  height: 40px;
  font-size: 5rem;
  z-index: 100;
  cursor: pointer;
  color: $red;
}
