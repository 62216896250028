@import "../index.scss";

.cgv {
  padding: 1em;
  summary {
    padding: 2rem;
    a {
      text-decoration: none;
      color: inherit;
      &:hover {
        color: $primary;
      }
      &:active {
        background-color: rgba($primary, 0.3);
      }
    }
  }
  &__mainOl {
    font-weight: 600;
    h3 {
      font-weight: 600;
    }
    p {
      font-weight: normal;
      padding: 0.5rem;
      q {
        font-style: italic;
      }
    }
  }
  &__borderBox {
    border: 0.1em solid $dark;
  }
  &__ulType {
    list-style: outside;
  }
}
