@import "../index.scss";

.nf {
  &__content {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      font-size: 2rem;
      padding: 1em;
      text-align: center;
      background: linear-gradient(-45deg, $primary, rgba($primary, 0.3));
      border-radius: 10px 75px;
      &:hover {
        background: linear-gradient(-45deg, rgba($primary, 0.3), $primary);
        border-radius: 75px 10px;
      }
      &:active {
        background: $primary;
      }
      transition: 0.3s ease all;
    }
    a {
      color: $dark;
      text-decoration: none;
    }
  }
}
