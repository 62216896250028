@import "../index.scss";

.avis {
  a {
    iframe {
      min-height: 100vh;
      width: 100%;
      border: none;
    }
  }
}
